@font-face {
  font-family: PressStart2P;
  src: url(./core/PressStart2P.ttf);
}

html, body {
  height: 100% !important;
  width: 100% !important;
}

body {
  margin: 0;
  font-family: PressStart2P, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: inherit;
  user-select: none;
}