.action-items {
  position: absolute;
  display: flex;
  bottom: 24px;
  right: 24px;

  button {
    cursor: pointer;
    height: 48px;
    padding: 0 12px;
    border: 0;
    background: white;
    box-shadow: 2px 4px 6px rgb(0 0 0 / 50%);
    transition: transform ease-in-out 200ms, box-shadow ease-in-out 200ms, background-color linear 200ms;

    &.website-portfolio-button {
      color: green;
      border-radius: 6px;
      transition: transform ease-in-out 200ms, background-color linear 200ms, color linear 200ms;
    }

    &.instagram {
      div {
        position: absolute;
        top: 0;
        left: 0;
        height: 48px;
        width: 48px;
        z-index: -1;
        transition: opacity linear 200ms;
      }

      .white-cover {
        opacity: 1;
        background: white;
      }

      .color-background {
        opacity: 0;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
      }
    }

    .fa-2x {
      transition: color linear 200ms;
    }

    .fa-instagram {
      color: rgb(206, 36, 65);
    }

    .fa-linkedin {
      color: rgb(0, 114, 177);
    }

    &:hover {
      transform: scale(1.1) translateY(-3px);
      box-shadow: 2px 4px 8px rgb(0 0 0 / 50%);

      &.instagram {
        .white-cover {
          opacity: 0;
        }

        .color-background {
          opacity: 1;
        }

        .fa-instagram {
          color: white;
        }
      }

      &.linkedin {
        background: rgb(0, 114, 177);

        .fa-linkedin {
          color: white;
        }
      }

      &.website-portfolio-button {
        color: white;
        background: green;
      }
    }
  }

  >button:not(:first-child) {
    margin-left: 20px;
  }

  .personal-button {
    width: 48px;
    border-radius: 50%;
    overflow: hidden;
  }
}